import React from "react"
import Pager from "react-bootstrap/Pagination"

const Pagination = ({ currentPath, currentPage, totalPages }) => {
  const items = []
  let i = currentPage - 2
  // Add first item and ellipsis if needed
  if (currentPage >= 1) {
    items.push(
      <Pager.Item key="1" href={`${currentPath}`}>
        1
      </Pager.Item>
    )
    if (currentPage > 4) {
      items.push(
        <Pager.Ellipsis
          key={currentPage - 3}
          href={`${currentPath}/${currentPage - 3}`}
        />
      )
    }
  }
  // Add middle buttons
  do {
    if (i >= 2) {
      items.push(
        <Pager.Item
          key={i}
          href={`${currentPath}/${i}`}
          active={i === currentPage}
        >
          {i}
        </Pager.Item>
      )
    }
    i += 1
  } while (i <= totalPages && i <= currentPage + 2)
  // Add last page and ellipsis if needed
  if (currentPage < totalPages - 4) {
    items.push(
      <Pager.Ellipsis
        key={currentPage + 3}
        href={`${currentPath}/${currentPage + 3}`}
      />
    )
  }
  if (currentPage <= totalPages - 3) {
    items.push(
      <Pager.Item key={totalPages} href={`${currentPath}/${totalPages}`}>
        {totalPages}
      </Pager.Item>
    )
  }

  return <Pager className="justify-content-center">{items}</Pager>
}
export default Pagination
