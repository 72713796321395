import React from "react"
import { graphql } from "gatsby"
import PublicationsListingTemplate from "../components/publication-listing"

const NewsReleasesListingTemplate = ({ data, pageContext }) => (
  <PublicationsListingTemplate data={data} pageContext={pageContext} />
)

export const query = graphql`
  query NewsReleasesQuery($limit: Int, $skip: Int) {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: {
        fileAbsolutePath: { regex: "/publications/news-releases/.*\\\\.md$/" }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          nid
          related_media {
            frontmatter {
              name
              path
              ext
            }
          }
        }
        html
      }
    }
  }
`

export default NewsReleasesListingTemplate
