import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Pagination from "./pagination"
import SEO from "./seo"
import Layout from "./layout"
import PublicationItemTemplate from "./publication-item"
import VideoHistoryBlock from "./VideoHistoryBlock"
import Breadcrumbs from "./breadcrumb"

const PublicationsListingTemplate = ({ data, pageContext }) => {
  const title = pageContext.title
  const totalPages = pageContext.totalPages
  // prettier-ignore
  const currentPath = pageContext.nextPageLink.substring(0, pageContext.nextPageLink.lastIndexOf("/"))
  const currentPage = pageContext.pageNumber
  const crumbs = [
    {
      title: "Archives",
      slug: "/archives"
    },
    {
      title: "Publications",
      slug: "/archives/publications"
    }
  ]

  return (
    <Layout>
      <SEO title={title} />
      <Row>
        <Col id="page-title" className="col col-12">
          <h1 className="title">{title}</h1>
        </Col>
      </Row>
      <Breadcrumbs crumbs={crumbs} />
      <Row id="content">
        <Col id="main-content">
          <div className="results-summary py-3">
            <strong>
              {data.allMarkdownRemark.totalCount} {pageContext.title} documents
            </strong>
          </div>
          <div className="publication-listing">
            <div>
              {data.allMarkdownRemark.nodes.map(item => (
                <PublicationItemTemplate
                  key={item.frontmatter.nid}
                  item={item}
                />
              ))}
            </div>
          </div>
        </Col>
        <Col md="12" lg="3">
          <div id="sidebar-second" className="sidebar">
            <VideoHistoryBlock />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            currentPath={currentPath}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </Col>
      </Row>
    </Layout>
  )
}

export default PublicationsListingTemplate
