import React from "react"

const PublicationItemTemplate = ({ item }) => {
  const title = item.frontmatter.title
  const html = item.html
  const relatedMedia = item.frontmatter.related_media[0]

  return (
    <div className="publication-item">
      {/* prettier-ignore */}
      <a href={`${relatedMedia.frontmatter.path}/${relatedMedia.frontmatter.name}.${relatedMedia.frontmatter.ext}`}>
        <strong>{title}</strong>
      </a>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default PublicationItemTemplate
