import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const VideoHistoryBlock = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "videophoto.jpg" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <div id="homepage-videoblock" className="block">
        <h4 className="block-title">
          GTRI Video History: "The Solution Institution"
        </h4>
        <div className="block-contents">
          <div className="text-center">
            <a
              href="/media/908"
              aria-label="View GTRI: The Solution Institution video"
            >
              <Img
                className="gtri-75-video"
                fixed={data.placeholderImage.childImageSharp.fixed}
              />
            </a>
          </div>
          <div>
            The history of GTRI is one filled with intrigue, innovation and
            impact. Learn about GTRI's exciting past, present and future.{" "}
            <a href="/media/908">View video</a>
          </div>
        </div>
      </div>
    )}
  />
)

export default VideoHistoryBlock
